<template>
	<div class="cbox">
		<div class="headbar">
			<div style="float: right;">

				<el-input size="small" placeholder="请输入内容" v-model="queryForm.keyword"
					style="width:300px;margin-right:20px">
					<el-select v-model="queryForm.ktype" slot="prepend" placeholder="请选择" style="width:100px">
						<el-option label="学生姓名" value="stu_name"></el-option>
						<el-option label="手机号" value="phone"></el-option>
						<el-option label="身份证号" value="id_number"></el-option>
					</el-select>

				</el-input>

				<el-input clearable size="small" placeholder="请输入学校名称" v-model="queryForm.school"
					style="width:200px;margin-right:20px">
				</el-input>

				<el-select clearable v-model="queryForm.grade_name" placeholder="选择年级" size="small"
					style="width:120px;margin-right:20px">
					<el-option label="小班" value="小班"></el-option>
					<el-option label="中班" value="中班"></el-option>
					<el-option label="大班" value="大班"></el-option>
					<el-option label="一年级" value="一年级"></el-option>
					<el-option label="二年级" value="二年级"></el-option>
					<el-option label="三年级" value="三年级"></el-option>
					<el-option label="四年级" value="四年级"></el-option>
					<el-option label="五年级" value="五年级"></el-option>
					<el-option label="六年级" value="六年级"></el-option>
					<el-option label="高一" value="高一"></el-option>
					<el-option label="高二" value="高二"></el-option>
					<el-option label="高三" value="高三"></el-option>
				</el-select>
				<el-select clearable v-model="queryForm.class_name" placeholder="选择班级" size="small"
					style="width:120px;margin-right:20px">

					<el-option label="1班" value="1班"></el-option>
					<el-option label="2班" value="2班"></el-option>
					<el-option label="3班" value="3班"></el-option>
					<el-option label="4班" value="4班"></el-option>
					<el-option label="5班" value="5班"></el-option>
					<el-option label="6班" value="6班"></el-option>
					<el-option label="7班" value="7班"></el-option>
					<el-option label="8班" value="8班"></el-option>
					<el-option label="9班" value="9班"></el-option>
					<el-option label="10班" value="10班"></el-option>
					<el-option label="11班" value="11班"></el-option>
					<el-option label="12班" value="12班"></el-option>
					<el-option label="13班" value="13班"></el-option>
					<el-option label="14班" value="14班"></el-option>
					<el-option label="15班" value="15班"></el-option>
					<el-option label="16班" value="16班"></el-option>
					<el-option label="17班" value="17班"></el-option>
					<el-option label="18班" value="18班"></el-option>
					<el-option label="19班" value="19班"></el-option>
					<el-option label="20班" value="20班"></el-option>
				</el-select>

				<el-select clearable v-model="queryForm.status" placeholder="保单状态" size="small"
					style="width:120px;margin-right:20px">
					<el-option label="所有状态" value=""></el-option>

					<el-option label="理赔中" value="0"></el-option>
					<el-option label="理赔拒绝" value="-1"></el-option>
					<el-option label="资料审核中" value="1"></el-option>
					<el-option label="理赔通过" value="2"></el-option>

				</el-select>

				<el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>


				<el-button size="small" type="text" icon="el-icon-download" @click="exportOrders">导出</el-button>


			</div>
			<div style="padding-top: 14px;">
				<el-breadcrumb>
					<el-breadcrumb-item>保单管理</el-breadcrumb-item>
					<el-breadcrumb-item>理赔保单</el-breadcrumb-item>

				</el-breadcrumb>
			</div>

		</div>
		<div class="bcontent">
			<el-table stripe :data="DataList" style="width: 100%;margin-bottom: 20px; color: #000;" border size="mini">
				<el-table-column type="index" label="编号" width="80">
				</el-table-column>
				<el-table-column prop="stu_name" label="学生姓名" width="120">
					<template slot-scope="scope">
						<span style="color:palevioletred" v-if="scope.row.gender == 2">{{ scope.row.stu_name }}</span>
						<span style="color:skyblue" v-if="scope.row.gender == 1">{{ scope.row.stu_name }}</span>

					</template>
				</el-table-column>
				<el-table-column prop="id_number" label="学生身份证号" width="160">

				</el-table-column>
				<el-table-column prop="grade_name" label="年级" width="120">

				</el-table-column>
				<el-table-column prop="parent_name" label="家长姓名" width="120">

				</el-table-column>
				<el-table-column prop="phone" label="家长手机号" width="120">

				</el-table-column>
				<el-table-column prop="bx_name" label="保险名称" min-width="120">

				</el-table-column>
				<el-table-column prop="total_money" label="保险金额" width="120">

				</el-table-column>




				<el-table-column prop="start_time" label="保险有效期" min-width="280">
					<template slot-scope="scope">
						{{ scope.row.start_time }} 至 {{ scope.row.end_time }}

					</template>
				</el-table-column>
				<!-- <el-table-column prop="id" label="支付日期" width="180">
					<template slot-scope="scope">
						{{scope.row.pay_time}}

					</template>
				</el-table-column> -->

				<el-table-column prop="status" label="状态" width="120">
					<template slot-scope="scope">
						<div v-if="scope.row.sp_status == 1"><span style="color:#1a50f5">资料审查中</span></div>
						<div v-if="scope.row.sp_status == -1"><span style="color:red">理赔拒绝</span></div>
						<div v-if="scope.row.sp_status == 0"><span style="color:orange">理赔审核中</span></div>
						<div v-if="scope.row.sp_status == 2"><span style="color:green">理赔通过</span></div>


					</template>
				</el-table-column>


				<el-table-column fixed="right" label="操作" width="120">
					<template slot-scope="scope">

						<el-button type="text" @click="viewInfo(scope.row)">查看</el-button>

						<el-button type="text" v-if="scope.row.status == 2" @click="viewInfo(scope.row)">处理</el-button>

					</template>
				</el-table-column>
			</el-table>

			<div style="height: 20px;">

			</div>


		</div>
		<div
			style="position: absolute; bottom: 0; height: 40px; width: 100%; text-align: right; background-color: #fff; z-index: 20;">
			<el-pagination @current-change="paginate" :current-page="Page.current_page" :page-size="Page.per_page"
				:total="Page.count" layout="total, prev, pager, next">
			</el-pagination>
		</div>



		<el-dialog title="理赔单详情" :visible.sync="dialogVisible" :close-on-click-modal="false" width="1000px">
			<div style="padding-right: 40px;">
				<el-form v-if="EditItem" :model="EditItem" ref="EditItem" label-width="120px">
					<div style="background:#eee;padding:10px 20px;border-radius: 4px;">基本信息</div>
					<el-row style="padding-top: 20px;">
						<el-col :span="12">
							<el-form-item label="学生姓名">
								<el-input readonly v-model="EditItem.stu_name"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="性别">
								<el-input readonly :value="EditItem.gender == 1 ? '男' : '女'"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="身份证号">
								<el-input readonly v-model="EditItem.id_number"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="出生日期">
								<el-input readonly v-model="EditItem.birthday"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="所在学校">
								<el-input readonly v-model="EditItem.school_name"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="年级">
								<el-input readonly v-model="EditItem.grade_name"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="班级">
								<el-input readonly v-model="EditItem.class_name"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="家长姓名">
								<el-input readonly v-model="EditItem.parent_name"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="家长手机号">
								<el-input readonly v-model="EditItem.phone"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="保险名称">
								<el-input readonly v-model="EditItem.bx_name"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="支付金额">
								<el-input readonly v-model="EditItem.total_money"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="支付时间">
								<el-input readonly v-model="EditItem.pay_time"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="保险有效期">
								<el-input readonly :value="EditItem.start_time + ' 至 ' + EditItem.end_time"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="保单号">
								<el-input readonly v-model="EditItem.pay_order_num"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="理赔状态">
								<div v-if="lipeiInfo.lipei.sp_status == 1"><span style="color:#1a50f5">资料审查中</span></div>
								<div v-if="lipeiInfo.lipei.sp_status == -1"><span style="color:red">理赔拒绝</span></div>
								<div v-if="lipeiInfo.lipei.sp_status == 0"><span style="color:orange">理赔审核中</span></div>
								<div v-if="lipeiInfo.lipei.sp_status == 2"><span style="color:green">理赔通过</span></div>

							</el-form-item>
						</el-col>

					</el-row>

					<div style="background:#eee;padding:10px 20px;border-radius: 4px;">理赔资料</div>

					<el-row style="padding-top: 20px;" v-if="lipeiInfo">
						<el-col :span="12">
							<el-form-item label="理赔提交时间">
								<el-input readonly v-model="lipeiInfo.lipei.cdate"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="理赔状态">

								<div v-if="lipeiInfo.lipei.sp_status == 0"><span style="color:#1a50f5">未处理</span></div>
								<div v-if="lipeiInfo.lipei.sp_status == 1"><span style="color:orange">资料审核中</span></div>
								<div v-if="lipeiInfo.lipei.sp_status == 2"><span style="color:blue">审核通过</span></div>
								<div v-if="lipeiInfo.lipei.sp_status == -1"><span
										style="color:blueviolet">资料审核不通过</span></div>


							</el-form-item>
						</el-col>


					</el-row>
					<el-row style="padding-top: 20px;" v-if="lipeiInfo">
						<el-col :span="24">
							<el-form-item label="理赔资料">

								<div class="imagebox">
									<div class="imageitem" @click="prevImage(item)"
										v-for="(item, index) in lipeiInfo.lipei.files" :key="index">

										<el-image style="width: 100%; height: 100%" :src="item.url" fit="cover"
											:preview-src-list="[item.url]" :z-index="3000"></el-image>

										<div class="imgname">{{ item.nameType }}</div>

									</div>
								</div>

							</el-form-item>
						</el-col>
					</el-row>

					<el-row style="padding-top: 20px;" v-if="lipeiInfo">
						<el-col :span="24">
							<el-form-item label="">
								<el-input v-model="lipeiInfo.lipei.sp_word" type="textarea" autosize
									placeholder="请输入审批状态描述"></el-input>
								<div style="font-size:12px;color:#1a50f5;cursor: pointer; line-height: 20px;">
									<div @click="setSpword">
										您的资料正在审核中，请保持手机畅通，我们的工作人员会第一时间联系您！
									</div>
									<div @click="setSpword">
										您的资料已审核通过，赔付款项将通过xx银行汇至相应账号，请注意查收！
									</div>
									<div @click="setSpword">
										您的资料不全，无法申请理赔！
									</div>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="">
								<div style="text-align:right">
									<el-button type="" @click="dialogVisible = false">关闭</el-button>
									<el-button type="" v-if="lipeiInfo.lipei.sp_status == 0"
										@click="startShenpi(1)">资料审核中</el-button>
									<el-button type="warning" v-if="lipeiInfo.lipei.sp_status == 0||lipeiInfo.lipei.sp_status == 1"
										@click="startShenpi(-1)">资料审核不通过</el-button>
									<el-button type="success" v-if="lipeiInfo.lipei.sp_status==0||lipeiInfo.lipei.sp_status == 1"
										@click="startShenpi(2)">资料审核通过</el-button>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>

		</el-dialog>

	</div>
</template>

<script>

export default {
	components: {

	},
	data() {
		return {
			temphead: "../../../static/temphead.jpg",
			queryForm: {
				ktype: "stu_name",
				keyword: "",
				grade_name: "",
				class_name: "",
				school: "",
				status: ""
			},
			activeName: "first",
			DataList: [],
			dialogVisible: false,
			showTongji: false,
			Page: {
				current_page: 1,
				next_page: 2,
				pre_page: 20,
				per_page: 20,
				total_page: 0,
				count: 0
			},
			product_catalog: [],
			userinfo: {},
			datainfo: [],
			read_teacher: [],
			read_teacher_num: 0,
			read_no_teacher: [],
			read_no_teacher_num: 0,
			read_student: [],
			detailTitle: "",
			EditItem: null,
			lipeiInfo: null,
			//按钮权限
			auth: {
				edit: false,
				delete: false
			},
			TypeList: [],
			DeptData: [],
			showPhone: false,
			canAdd: false,
			canEdit: false,
			sp_word: ""

		}
	},

	mounted() {
		this.search()
	},
	methods: {


		paginate(val) {
			this.Page.page = val;
			this.query(val)
		},
		search: function () {
			this.query(1);
		},
		query(page) {

			this.queryForm.page = page
			this.$http.post('/api/lipei_list', this.queryForm).then(res => {
				for (let item of res.data.data) {
					item.stime = new Date(item.start_time.replace(/-/g, '/')).getTime()
					item.etime = new Date(item.end_time.replace(/-/g, '/')).getTime()
					item.ntime = new Date(item.ntime.replace(/-/g, '/')).getTime()

					item.start_time = item.start_time.split(' ')[0]
					item.end_time = item.end_time.split(' ')[0]
					//判断是否过期
					item.time_status = 0;
					if (item.ntime > item.etime) {
						item.time_status = -1
					} else if (item.ntime > item.stime && item.ntime < item.etime) {
						item.time_status = 1
					}
				}
				this.DataList = res.data.data;

				this.Page = res.data.page


			});
		},
		update(item) {

			sessionStorage.setItem("product", JSON.stringify(item))

			this.$router.push("/proedit?" + item.id)
		},
		add() {
			this.$router.push("/notifyedit")
		},
		deleteItem(item) {
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/notify_delete", {
					id: item.id
				}).then(res => {
					this.$message({
						type: 'success',
						message: '删除成功'
					});

					this.query();
				})

			}).catch(() => {

			});
		},


		viewInfo(item) {

			this.EditItem = item
			this.dialogVisible = true
			this.getLipei(item.bd_id)
			// this.$http.post("/api/article_info", {
			// 	id: item.id,

			// }).then(res => {

			// })
		},

		editItem(item) {
			this.$router.push("/notifyedit?id=" + item.id)
		},
		setTop(item) {
			this.$http.post("/api/article_top", {
				id: item.id,
				top: item.top ? 0 : 1
			}).then(res => {
				this.$message({
					type: 'success',
					message: '置顶成功'
				});

				this.query();
			})
		},

		closePhone() {
			this.showPhone = false
			this.showTongji = false
		},
		viewDetail(e) {
			this.EditItem = e
			this.showTongji = true

		},
		getLipei(bdid) {
			this.$http.post("/api/get_lipei_detail", { bd_id: bdid, id: this.EditItem.lp_id }).then(res => {
				if (res.data.lipei.files) {
					res.data.lipei.files = JSON.parse(res.data.lipei.files)
				}
				this.lipeiInfo = res.data
			})
		},
		prevImage(e) {

		},
		startShenpi(e) {
			let msg="";
			if(e==1){
				msg="确认进入审核理赔资料流程？"
			}else  if(e==2){
				msg="确认理赔资料审核通过？"
			}else  if(e==-1){
				msg="确认拒绝理赔资料？请写明审核不通过的原因，以便用户收集后重新提交！"
			}
			this.$confirm(msg, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/lipei_sp", {
					bd_id: this.EditItem.bd_id,
					id: this.lipeiInfo.lipei.id,
					sp_word: this.lipeiInfo.lipei.sp_word,
					sp_status: e
				}).then(res => {
					this.$message({
						type: 'success',
						message: '审核成功!'
					});
					this.search()
					this.dialogVisible = false
				})



			}).catch(() => {

			});
		},
		setSpword(e) {
			this.lipeiInfo.lipei.sp_word = $(e.target).text()

		},
		exportOrders() {
			this.$http.post('/api/baodan_list_export', this.queryForm).then(res => {
				if (res.data.url) {
					window.open(res.data.url, "_blank")
				}
			});
		}




	}
}
</script>
<style scoped type="text/less" lang="less">
.pageContainer {
	height: 100%;
}

.imageitem {
	width: 150px;
	height: 200px;
	border: 1px dotted #ccc;
	box-sizing: border-box;
	display: inline-block;
	vertical-align: top;
	margin-top: 10px;
	margin-bottom: 10px;
	position: relative;
	background-size: cover;
	background-position: center;
	position: relative;

}

.imagebox>.imageitem:nth-child(2n+1) {
	margin-right: 12px;
}

.imgname {
	height: 40rpx;
	text-align: center;
	position: absolute;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
	font-size: 12px;
	width: 100%;
	line-height: 20px;

}
</style>
